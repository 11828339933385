footer {
    padding: 2em 0;
    text-align: center;
    background-color: #F9ECF1;
    color: #000;
}

footer p {
    margin: 0;
    font-size: 0.875em;
    font-weight: bold;
    font-size: 1.2em;
}
