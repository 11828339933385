#about {
  padding: 2em;
  background-color: #F0F0F0;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#about h1 {
  font-size: 2.5em;
  margin-bottom: 2em;
  color: #333;
}

.about-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}

.profile-photo-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 2em;
}

.profile-photo {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.fun-fact {
  font-size: 1.2em;
  color: #333;
  margin-top: 1em;
  text-align: center;
}

.about-details {
  flex: 2;
}

.about-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 2em;
  margin-bottom: 2em;
}

.about-card h2 {
  font-size: 1.5em;
  margin-bottom: 1em;
  color: #333;
}

.about-card p {
  font-size: 1.1em;
  line-height: 1.6em;
  text-align: left;
  margin-bottom: 1em; 
}

/* CSS Media Query for Mobile */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
  }
  
  .profile-photo-container {
    margin-right: 0;
    margin-bottom: 2em;
  }
  
  .profile-photo {
    width: 200px;
    height: 200px;
  }
  
  .about-details {
    flex: none;
  }
}
