nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #000000;
  padding: 1em 0em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #fff;
  z-index: 1; /* This will keep the navbar on top of other elements */
  /*increase the font size of the links */
  font-size: 1.2em;
}

nav a {
  margin: 0 1em;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease; /* This will animate the color change */
}

.right {
  display: flex;          /* Added this line */
  align-items: center;    /* Added this line */
}

.right a {
  color: #fff;
  margin-left: 1.9em; /* This will add some space between the icons */
}

nav a:hover {
  color: #DD9751; /* This will change the link color when hovered */
}

.nav-links {
  display: flex;
  justify-content: space-between;
  width: 25%; /* Adjust this value to change the spacing between the links */
}

.hamburger {
display: none;
flex-direction: column;
justify-content: space-around;
width: 2rem;
height: 2rem;
cursor: pointer;
z-index: 10;
}

.hamburger div {
width: 2rem;
height: 0.25rem;
background-color: #fff;
border-radius: 5px;
transition: all 0.3s linear;
}

@media(max-width: 768px) {
  .nav-links {
    display: none;
  }

  .mobile-nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #000;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    right: 0;
    top: 60px;
    text-align: center;  /* Added this line */
    padding: 1em 0;      /* Added this line */
  }

  .hamburger {
    display: flex;
  }

  /* Added these lines */
  .mobile-nav-links a {
    padding: 1em 0;
  }

  .right {
    flex-direction: column;  /* Added this line */
    align-items: center;     /* Added this line */
  }

  .right a {
    margin-left: 0;          /* Added this line */
    margin-bottom: 1em;      /* Added this line */
  }
}
