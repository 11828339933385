#projects {
    padding: 2em;
    background-color: #AFD5E7;
    color: #333; /* Dark gray text should be easy to read on the light blue background */
  }
  
#projects h1 {
    text-align: center;
    margin-bottom: 2em;
}

.projects-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2em;
}

.project-card {
    width: 300px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    position: relative;
    display: flex;
    flex-direction: column;
}

.project-card:hover {
    transform: scale(1.05);
}

.project-card img {
    width: 100%;
    height: 300px; /* Set a fixed height */
    object-fit: cover; /* This will make sure the images cover the entire area without distorting their aspect ratio */
    transition: opacity 0.3s ease-in-out;
}

.project-card:hover img {
    opacity: 0.7;
}

.project-info {
    background-color: #003366;
    padding: 1em;
    text-align: center;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    color: #FDF5E6;
    font-size: 1.1em;
}

.project-info h2 {
    font-size: 1.2em;
    margin-bottom: 0.5em;
}

.project-info p {
    font-size: 0.9em;
    font-weight: 300;
    margin-bottom: 0.5em;
}

.view-project {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.5em 1em;
    background-color: #333;
    color: #fff;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.project-card:hover .view-project {
    opacity: 1;
}

.view-project:hover {
    background-color: #666;
}